import styles from './Footer.module.css';
import chat from '../../img/chat.svg';
import { Button, Container, Grid } from '@mui/material';
import { PrimaryButton } from '../form/PrimaryButton';

export function Footer({ loading, icon, text, text2, secondary, onClick, onClick2 }) {
  return (
    <div className={secondary ? styles.home_footerSecundary : styles.home_footer}>
      <Container>
        <Grid container justifyContent="space-between" >
          <Grid item>
            {secondary === 'chat' && (
              <></>
              // <img src={chat} alt='chat' className={styles.home_buttonChat} onClick2={onClick2} />
            )}
            {text2 && <Button variant='contained' className={styles.home_buttonPrev} onClick={onClick2}>
                {text2}
              </Button>}
            
          </Grid>
          <Grid item>
            {secondary === 'chat' && (
              <img src={chat} alt='chat' className={styles.home_buttonChat} onClick2={onClick2} />
            )}
          </Grid>
          <Grid item>
            <PrimaryButton loading={loading} text={text} className={styles.home_buttonNext} onClick={onClick} next={icon}>
              {text}
            </PrimaryButton>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}