import { ListItem } from './ListItem.jsx'
import styles from './ListScroll.module.css';
import pricecheck from '../../img/pricecheck.svg';
import list from '../../img/list.svg';
import house from '../../img/house.svg';
import computer from '../../img/computer.svg';
import numero1 from '../../img/numero1.svg';
import numero2 from '../../img/numero2.svg';
import numero3 from '../../img/numero3.svg';
import numero4 from '../../img/numero4.svg';
import video from '../../img/video.svg';

const data = [
    { id: 1, icon1: numero1, icon2: house, text: 'Conte sobre o seu negócio' },
    { id: 2, icon1: numero2, icon2: list, text: 'Identificamos suas necessidades' },
    { id: 3, icon1: numero3, icon2: computer, text: 'Detalhamos o seu projeto' },
    { id: 4, icon1: numero4, icon2: pricecheck, text: 'Entregamos um plano, preço e prazo' },
]

const ListScroll = () => {
    return (
        <div className={styles.externo}>
        <div className={styles.list}>
            {data.map(item => (
                <div className={styles.itemlist} key={item.id}>
                <ListItem key={item.id} icon1={item.icon1} icon2={item.icon2} text={item.text} />
                </div>
            ))}
        </div>
        <div className={styles.divVideo}>
        <iframe className={styles.video} src="https://www.youtube.com/embed/iKOStRXWX3o?controls=0&rel=0&showinfo=0&showinfo=0&modestbranding=0" title="Start CheckStore" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        </div>
    )
};

export default ListScroll