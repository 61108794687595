import ProgressBar from '../form/ProgressBar.jsx';
import styles from './Header.module.css';
import strike from '../../img/strikelogo.svg';
import arrow from '../../img/arrow.svg';


export function Header({ back, progressbar, stage, rate, onClick }) {
        return (
                <header className={styles.home_header}>
                        {(back === 'with' && progressbar === 'with') ?
                                <div className={styles.items}>
                                        <div onClick={onClick} className={styles.item}>
                                        {/* <img src={arrow} alt="arrow" className={styles.arrow} /> */}
                                        </div>
                                        <div>
                                        <img src={strike} alt="strike" className={styles.home_img} />
                                        </div>
                                        <div>
                                        {/* <ProgressBar stage={stage} rate={rate} /> */}
                                        </div>
                                </div> 
                                : 
                                back === 'with' ?
                                        <div className={styles.double}>
                                                <img src={arrow} alt="arrow" className={styles.arrow} />
                                                <img src={strike} alt="strike" className={styles.home_img} />
                                                
                                        </div>
                                        :
                                        <img src={strike} alt="strike" className={styles.home_img} />
                        }
                </header>
        )
}