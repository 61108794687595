import { Container } from '@mui/material';
import styles from './Content.module.css';

export function Content({ children }) {
    return (
        <Container>
            <div className={styles.content}>
                <div>
                    {children}
                </div>
            </div>
        </Container>
    )
}