import { Autocomplete, Box, TextField } from '@mui/material';
import { useState, useEffect } from 'react';

import styles from './Input.module.css';
import { api } from '../../config';

const Input = ({ error, id, sx, handleChange, options, multiple = false, autoComplete }) => {
  const [selectedOption, setSelectedOption] = useState(multiple ? [] : null);
  const [isEditable, setIsEditable] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  const handleInputClick = (e) => {
    setClickCount(clickCount + 1);
    if (clickCount === 1) {
      setIsEditable(true);
    }
  };

  useEffect(() => {
    setSelectedOption(multiple ? [] : null);
  }, [id]);

  return (
    <Box width={"100%"}>
      <Autocomplete
        multiple={multiple}
        className={styles.input}
        disablePortal
        id={id}
        value={selectedOption}
        options={options}
        defaultValue={null}
        onChange={(e, value) => {
          setSelectedOption(value);
          handleChange(value.label ? value.label : multiple ? value.map(elem => elem.label) : value);
          e.target.blur()
          setIsEditable(false)
          setClickCount(0)
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.label}
        disableCloseOnSelect={multiple}
        renderInput={(params) => <TextField {...params}
          className={styles.field}
          label="Selecione"
          error={error.value ? true : false}
          helperText={error?.value || ''}
          inputProps={{ ...params.inputProps, readOnly: !isEditable }}
          onClick={handleInputClick} />}
        noOptionsText={"Sem opções"}    />
        {
          selectedOption?.thumbnail?.origin && <img className={styles.image} src={api + "/files/form/" + selectedOption.thumbnail.origin} alt={selectedOption.thumbnail.name} />
        }
    </Box>
  )
}

export default Input;