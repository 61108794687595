import { useState, useEffect } from 'react';
// import { Navigate } from 'react-router-dom';
import logocheckstore from '../../img/logocheckstore.svg';
import strike from '../../img/strikelogo.svg';
import styles from './ScreenSplash.module.css';

const SplashScreen = () => {
    const [image1, setImage1] = useState(false);
    const [image2, setImage2] = useState(false);
    const [showSplash, setShowSplash] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setImage1(true);
        }, 1000);
        setTimeout(() => {
            setImage2(true);
        }, 2000);
        setTimeout(() => {
            setImage1(false);
            setImage2(false);
        }, 3000);
        setTimeout(() => {
            setShowSplash(false);
        }, 4000);
    }, []);

    return (
        <div className={`${styles.container} ${!showSplash ? styles.fadeOut : ""}`}>
            <img
                src={strike} alt="strike"
                className={`${styles.image} ${styles.logo}  ${image1 ? styles.fadeIn : ""}`}
            />
            <img
                src={logocheckstore} alt="logocheckstore"
                className={`${styles.image} ${image2 ? styles.fadeIn : ""}`}
            />
        </div>
    );
};

export default SplashScreen;