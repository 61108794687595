import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Welcome } from './components/pages/Welcome.jsx';
import { Oficial } from './components/oficial/Oficial.jsx';


function App() {

  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Oficial />} />
        <Route exact path='/:id' element={<Oficial />} />
        <Route path='/components' element={<Welcome />} />
      </Routes>
    </Router>
  );
}

export default App;

