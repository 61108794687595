import { useState } from 'react';
import styles from './Counter.module.css';



const Counter = ({handleChange}) => {

    const [count, setCount] = useState(0);
    
    return (
        <div className={styles.count}>
        <button onClick={() => {
          if(count > 0) {
            handleChange(count - 1)
             setCount(count - 1)
          }
        }}>-</button>
        <p>{count}</p>
        <button onClick={() => {
          handleChange(count + 1)
          setCount(count + 1)
        }}>+</button>
      </div>
    );
}

export default Counter;

