import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import styles from './PrimaryButton.module.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export function PrimaryButton({ loading, text, type = 'default', onClick = () => {}, next = false }) {
    
    const className =
      type === 'strong' ? 'btn-strong' : type === 'weak' ? 'btn-weak' : 'btn';
  
    return (
      <LoadingButton sx={{
        '& .MuiCircularProgress-root': {
          color: 'white'
        }
      }} loading={loading} variant='contained' className={styles[className]} onClick={onClick}>
        {text} {next && <ArrowForwardIosIcon />}
      </LoadingButton>
    );
}