import styles from './Select.module.css';
import { FormGroup, FormControlLabel, Checkbox, Alert } from '@mui/material'
import { useState, useCallback } from 'react';

export function Select({ error, options, handleChange }) {
    const [selectedOption, setSelectedOption] = useState([]);

    const handleSelectChange = useCallback((value) => {
        let newSelectedOption = [...selectedOption];
        if (newSelectedOption.includes(value)) {
            newSelectedOption = newSelectedOption.filter((v) => v !== value)
        } else {
            newSelectedOption.push(value);
        }
        setSelectedOption(newSelectedOption);
        handleChange(newSelectedOption);
    }, [selectedOption, handleChange]);


    return (
        <>
        
        {error.value !== "" &&
            <Alert severity="error">{error.value}</Alert>}
        <div className={styles.select}>
            <FormGroup>
                {options?.map(elem => {
                    return (
                        <div key={elem.id} className={selectedOption?.includes(elem.value) ? (elem.text ? styles.selectedComment : styles.selectedOption) : (elem.text ? styles.unselectedComment : styles.unselectedOption)}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color='default'
                                        checked={selectedOption?.includes(elem.value)}
                                        onClick={(e) => handleSelectChange(elem.value)}
                                    />}
                                label={<><h3>{elem.label}</h3>
                                    <h5 dangerouslySetInnerHTML={{ __html: elem?.text }} /></>}
                            />
                        </div>
                    )
                })}
            </FormGroup>
        </div></>
    )
}

export function SelectComment({ options }) {

    const [selectedOption, setSelectedOption] = useState([]);

    const handleSelectChange = useCallback((value) => {
        if (selectedOption.includes(value)) {
            setSelectedOption(selectedOption?.filter((v) => v !== value))
        } else {
            setSelectedOption([...selectedOption, value])
        }
    }, [selectedOption]);

    console.log(selectedOption)

    return (
        <div className={styles.select}>
            <FormGroup>
                {options.map(elem => {
                    return (
                        <div key={elem.id} className={selectedOption.includes(elem.value) ? styles.selectedOptionComment : styles.unselectedOptionComment}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color='default'
                                        checked={selectedOption.includes(elem.value)}
                                        onClick={() => handleSelectChange(elem.value)}
                                    />}
                                label={<h3>{elem.label}</h3>}
                            />
                            <h5>{elem?.text}</h5>
                        </div>
                    )
                })}
            </FormGroup>
        </div>
    )
}
