import { useState } from 'react';
import { TextField } from '@mui/material';
import styles from './Data.module.css';
import { SetMeal } from '@mui/icons-material';
import InputMask from 'react-input-mask';

const Data = ({ error, handleName, handleEmail, handlePhone, valueName, valueEmail, valuePhone }) => {
    return (

        <div className={styles.container}>
            <h2>Dados Pessoais</h2>
            <div className={styles.data}>
                <div>
                    <h4>primeiro nome ou apelido</h4>
                    <TextField
                        type='text'
                        error={error?.name ? true : false}
                        label='Como você quer ser chamado...'
                        variant='standard'
                        onChange={(e) => handleName(e.target.value)}
                        value={valueName}
                        InputLabelProps={{ style: { color: 'var(--white-400' } }}
                        className={styles.field}
                        helperText={error.name || ''}
                    />
                </div>
                <div>
                    <h4>e-mail</h4>
                    <TextField
                        type='email'
                        error={error?.email ? true : false}
                        label='exemplo@mail.com'
                        variant='standard'
                        onChange={(e) => handleEmail(e.target.value)}
                        value={valueEmail}
                        InputLabelProps={{ style: { color: 'var(--white-400' } }}
                        className={styles.field}
                        helperText={error.email || ''}
                    />
                </div>
                <div>
                    <h4>whatsapp</h4>
                    <InputMask
                        error={error?.phone ? true : false}
                        label='(XX) XXXXX-XXXX'
                        mask="(99) 99999-9999"
                        variant='standard'
                        onChange={(e) => handlePhone(e.target.value)}
                        value={valuePhone}
                        InputLabelProps={{ style: { color: 'var(--white-400' } }}
                        className={styles.field}
                        helperText={error.phone || ''}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    >
                        {(inputProps) => (
                            <TextField
                                {...inputProps}
                                
                            />
                        )}
                    </InputMask>
                </div>
            </div>
        </div>
    )
}

export default Data;