import styles from './ListItem.module.css';

export function ListItem({icon1, icon2, text }) {
    return (
        <div className={styles.item}>
            <img src={icon1} alt={'icon1'} className={styles.img1}/>
            <img src={icon2} alt={'icon2'} className={styles.img2}/>
            <p>{text}</p>
        </div>
    )
};