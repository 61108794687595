import { api } from '../../config';
import styles from './Option.module.css';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Alert } from '@mui/material';

const Option = ({ error, options, onChange, selectedOption, handleChange, id }) => {

    const optionClass = (value) => {
        return selectedOption === value ? styles.selectedOption : styles.unselectedOption;
    };


    return (
        <div className={styles.box}>
            {error.value !== "" &&
                <Alert severity="error">{error.value}</Alert>}
            <FormControl className={styles.box}>
                <FormLabel id='moment'></FormLabel>
                <RadioGroup
                    id={id}
                    aria-labelledby="moment"
                    name='moment'
                    className={styles.radioGroup}
                    defaultValue={''}
                    value={selectedOption}
                >
                    {options.map(elem => {
                        return (
                            <div className={optionClass(elem.value)} key={elem.id}>
                                <FormControlLabel
                                    key={elem.id}
                                    className={styles.radio}
                                    control={
                                    <div style={{display: "flex"}}>
                                        <Radio value={elem.value} onClick={(e) => { handleChange(e.target.value || '') }} />
                                        {
                                            elem?.thumbnail?.origin && <img className={styles.image} src={api + "/files/form/" + elem.thumbnail.origin} alt={elem.thumbnail.name} />
                                        }
                                    </div>}
                                    label={
                                        <div className={styles.div_option}>
                                            <h3>{elem.label}</h3>
                                            {elem.text && <h5 dangerouslySetInnerHTML={{ __html: elem.text }} />}
                                        </div>}
                                >
                                </FormControlLabel>
                            </div>
                        )
                    })}
                </RadioGroup>
            </FormControl>
        </div>
    )
}

export default Option;