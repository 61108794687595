import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Option from '../form/Option.jsx';
import Data from '../form/Data.jsx';
import Counter from '../form/Counter.jsx';
import { Select, } from '../form/Select.jsx';
import { Header } from '../layout/Header.jsx';
import { Footer } from '../layout/Footer.jsx';
import { Content } from '../layout/Content.jsx';
import styles from './Oficial.module.css';
import ListScroll from '../form/ListScroll.jsx';
import Input from '../form/Input.jsx';
import SplashScreen from '../../components/form/ScreenSplash.jsx';
import { api } from '../../config';
import { CSSTransition } from 'react-transition-group';


export function Oficial({ icon, text, text2, secondary, back, progressbar, rate, stage }) {
    const [history, setHistory] = useState([])
    const [step, setStep] = useState({})
    const [form, setForm] = useState([])
    const [formInitial, setFormInitial] = useState([])
    let { id } = useParams();
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState("")
    const [start, setStart] = useState(false)
    const [end, setEnd] = useState(false)
    const [stepNumber, setStepNumber] = useState(0)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [error, setError] = useState({
        name: "",
        email: "",
        phone: "",
        value: ""
    });
    const [idProject, setId] = useState('');

    const [showSplash, setShowSplash] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setShowSplash(false);
        }, 5000);
    }, []);
    useEffect(() => {
        if (id) {
            setStart(true);
            setId(id);
            axios.get(api + "/projects/" + id).then(response => {
                setForm(response.data.project.form)
                setHistory(response.data.project.history || [])
                setStep(response.data.project.form[(response.data.project.stepForm || 0) + 1])
                setStepNumber((response.data.project.stepForm || 0) + 1)
            })
        } else {
            if (!start) {
                axios.get(api + "/form").then(response => {
                    setFormInitial(response.data.form.form)
                    setForm(response.data.form.form)
                    setStep(response.data.form.form[0])
                })
            }
        }
    }, [])
    const handleStep = async () => {
        setLoading(true)

        // await new Promise(resolve => setTimeout(resolve, 500));
        let error = false;
        let errorState = {};
        setError({
            name: "",
            email: "",
            phone: "",
            value: ""
        })
        if (stepNumber === 0) {
            
            if (name === "") {
                error = true;
                errorState = {
                    ...errorState,
                    name: "O nome é obrigatório!"
                };
            } else if(!/^[a-zA-Z\u00C0-\u017F.\s]+$/.test(name)) {
                error = true;
                errorState = {
                    ...errorState,
                    name: "Digite um nome válido!"
                };
            }
            const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (email === "") {
                error = true;
                errorState = {
                    ...errorState,
                    email: "O e-mail é obrigatório!"
                };
            } else if (!emailRegex.test(email)) {
                error = true;
                errorState = {
                    ...errorState,
                    email: "O e-mail digitado é inválido!"
                };
            }

            if (phone === "") {
                error = true;
                errorState = {
                    ...errorState,
                    phone: "O WhatsApp é obrigatório!"
                };
            } else if (phone.replace("_", "").length < 15) {
                error = true;
                errorState = {
                    ...errorState,
                    phone: "Informe um WhatsApp válido!"
                };
            }
            if (error) {
                setError(errorState)
                setLoading(false)
                return false
            };
            const values = {
                name,
                email,
                phone,
                form
            }
            const response = await axios.post(api + "/customers-project", values).then(resp => resp).catch(err => console.error(err));
            setId(response.data.id);
            setStep(form[stepNumber + 1]);
        } else {
            if (value === "") {
                setError({ value: "Selecione uma opção." })
                setLoading(false)
                return false
            }
            let aux = form;
            if (form[stepNumber + 1].type === "branch") {
                if (form[stepNumber + 1].children.find(elem => elem.name === value) || form[stepNumber + 1].children.filter(elem => value?.includes(elem.name)).length > 0) {
                    if (step.data?.fieldType === 'multiple' || step.data?.fieldType === 'multiple_option') {
                        let auxForm = form[stepNumber + 1].children.filter(elem => value.includes(elem.name));
                        for (let elem in auxForm) {
                            aux.splice(stepNumber + 2, 0, ...auxForm[elem].children)
                        }
                    } else {
                        console.log(aux);
                        aux.splice(stepNumber + 2, 0, ...form[stepNumber + 1].children.find(elem => elem.name === value)?.children)
                    }
                    aux.splice(stepNumber + 1, 1);
                } else if (form[stepNumber + 1].children.find(elem => elem.name === "Exceção")) {
                    aux.splice(stepNumber + 2, 0, ...form[stepNumber + 1].children.find(elem => elem.name === "Exceção")?.children)
                    aux.splice(stepNumber + 1, 1);
                }
                setStep(aux[stepNumber + 1]);
            } else {
                setStep(form[stepNumber + 1]);
            }

            aux[stepNumber].data.value = value;
            let data = { form: aux, id: idProject, stepForm: stepNumber };
            if (form[stepNumber + 1].type === "end") {
                setEnd(true);
                aux = aux.slice(1, -1);
                data = {
                    ...data,
                    form: [],
                    history: [],
                    status: 'waiting_price',
                    data: aux.map(elem => ({
                        name: elem.data.name,
                        value: elem.data.value,
                    })
                    )
                }
            }
            var historyAux = [...history, { ...form }];
            setHistory(historyAux)
            data.history = historyAux;
            await axios.put(api + "/project-form", data).then(resp => resp).catch(err => console.error(err));
            setForm(aux)
        }
        setValue("")
        setStepNumber(stepNumber + 1);
        setLoading(false)
        window.scrollTo(0, 0)
    }
    const handlePreviousStep = () => {
        setStep(step - 1);
    };
    if (step === 0) {
        text = 'Começar'
        icon = true
    } else {
        text = 'Avançar'
        secondary = 'chat'
        icon = true
    }

    text2 = 'Voltar'
    return (<>
        {showSplash ? <SplashScreen /> : <></>}
        <div className={styles.container}>
            <Header back={back} progressbar={progressbar} stage={stage} rate={rate} onClick={handlePreviousStep} />
                <Content>
                    {!start ? <ListScroll />
                        :
                        stepNumber === 0 ? <Data error={error} handleName={setName} valueName={name} handleEmail={setEmail} valueEmail={email} handlePhone={setPhone} valuePhone={phone} /> :
                            <div style={end ? { "display": "flex", alignItems: "center", height: "calc(100vh - 141px)", justifyContent: "center", flexDirection: "column" } : {}}>
                                <div className={styles.texts}>
                                    {stepNumber === 1 && <h1>Olá, {name}!</h1>}
                                    <h2>{end ? "Obrigado!" : (step.data?.name || step?.name)} </h2>
                                    <h5 dangerouslySetInnerHTML={{ __html: step.data?.description !== "<p><br></p>" && step.data?.description ? step.data?.description : "" }} />
                                </div>
                                <div className={styles.containerOptions}>
                                    {end ?
                                        <h3 align="center">Nossa equipe irá analisar as suas informações e retornaremos para você.</h3>
                                        : <>
                                            {step.data?.fieldType === 'count' && <Counter handleChange={setValue} />}
                                            {step.data?.fieldType === 'single' &&
                                                <Input error={error} options={step.data?.options.map((elem) => (
                                                    {
                                                        value: elem.value,
                                                        label: elem.value,
                                                        text: elem.description,
                                                        thumbnail: elem.thumbnail, 
                                                    }
                                                ))} id={`input-${stepNumber}`} handleChange={setValue} multiple={false} />
                                            }
                                            {step.data?.fieldType === 'multiple' &&
                                                <Input error={error} options={step.data?.options.map((elem) => (
                                                    {
                                                        value: elem.value,
                                                        label: elem.value,
                                                        text: elem.description
                                                    }
                                                ))} handleChange={setValue} multiple={true} id={`input-${stepNumber}`} />
                                            }
                                            {step.data?.fieldType === 'multiple_option' &&
                                                <Select error={error} options={step.data?.options.map((elem) => (
                                                    {
                                                        value: elem.value,
                                                        label: elem.value,
                                                        text: elem.description
                                                    }
                                                ))} handleChange={setValue} selectedOption={value} />
                                            }
                                            {step.data?.fieldType === 'single_option' &&
                                                <Option error={error} options={step.data?.options.map((elem) => (
                                                    {
                                                        value: elem.value,
                                                        label: elem.value,
                                                        text: elem.description,
                                                        thumbnail: elem.thumbnail, 
                                                    }
                                                ))} handleChange={setValue} selectedOption={value} id={`option-${stepNumber}`} />
                                            }
                                        </>}
                                </div>
                            </div>
                    }
                </Content>
            <Footer loading={loading} text={end ? "Visite nosso site" : text} onClick2={() => {
                const auxHistory = history.pop();
                let aux = Object.values(auxHistory)
                if (auxHistory.length === 0) aux = formInitial;
                setStepNumber(stepNumber - 1);
                setForm(aux)
                setStep(aux[stepNumber - 1]);
            }} secondary={secondary} icon={icon} text2={stepNumber > 1 ? text2 : false} onClick={end ? () => window.open("https://checkstore.com.br", "_blank") : (!start ? () => { setStart(true); window.scrollTo(0, 0) } : handleStep)} />
        </div>
    </>
    )
}